import { GetInventoryRequest, Kardex } from "@api/query/inventoryApi/types";
import { useLazyGetKardexQuery, useFindInventoryTypesQuery, useDownloadDataInventoryRecordsMutation } from "@api/query/inventoryApi/inventory";
import { DataGrid, DataGridRowContent, DataGridToolbar, GlobalFilterCallback, HighlightText, cellsById } from "@components/DataGrid";
import PageHeader from "@components/PageHeader";
import { useLazyPagination } from "@containers/PriceList/UseLazyPagination";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { CellProps, Column } from "react-table";
import { CheckPicker, DateRangePicker, Tooltip, Whisper, Tag, IconButton, Message, toaster } from "rsuite";
import dayjs, { dayjsCalendar, dayjsRanges, formats } from "@utils/dayjs";
import { useDispatch, useSelector } from "react-redux";
import { setOpenAuthModal } from "@actions/index";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { useFindLocationsQuery } from "@api/query/customerApi/customerApi";
import { FaFileExcel } from "react-icons/fa";
import * as constants from '../../utils/Constants';
import { ModalExportData } from "@components/Modals/ModalExportData";
import { getMainLoggingParams } from "@selectors/SystemSelector";

export default function ListKardexContainer() {
	const dispatch = useDispatch();
	const [triggerGetKardex, result] = useLazyGetKardexQuery()
  const [openExportData, setOpenExportData] = useState(false);
  const { maxNumberDownloadAllowed } = useSelector(getMainLoggingParams);
  const [extraQueryValues, setExtraQueryValues] = useState({});
  const [setDownload] = useDownloadDataInventoryRecordsMutation();

	const trigger = useCallback(
		(value: GetInventoryRequest) => {
			return triggerGetKardex({
				...value,
			})
				.then(res => {
					if ((res?.error as FetchBaseQueryError)?.status === 401) {
						dispatch(setOpenAuthModal({ open: true }))
					}
				})
		},
		[triggerGetKardex],
	);

  const submitExport = (data) => {
		const body = { ...data, ...extraQueryValues }
		setDownload(body)
			.then(resp => {
			try {
				const menssageError = resp
				if (menssageError) {
					toaster.push(
						<Message
							type="success"
							showIcon
							closable
							duration={constants.NOTIFICATION_DURATION}
						>
							{'El archivo se está generando y se enviará un correo electrónico con un link de descarga.'}
						</Message>
					);
				}
			} catch (error) {
				console.error('Error en la promesa:', error);
			}
		})
  };

	const defaultEndtDate = new Date();
	const defaultStartDate = new Date();
	defaultStartDate.setDate(defaultStartDate.getDate() - 30);
	
	const locationsData = useFindLocationsQuery({});

	const locationsOptions = useMemo(
    () =>
      locationsData?.data?.items.map(value => ({
        value: value.name,
        label: value.name,
      })),
    [locationsData?.data],
	);

	const inventoryTypesData = useFindInventoryTypesQuery({});

  const handleOnClickExportCSV = () => {
		if (virtualArray?.ref?.length > 0) {
			setOpenExportData(true)
		} else {
			toaster.push(
				<Message
					type="error"
					showIcon
					closable
					duration={constants?.NOTIFICATION_DURATION}
				>
					{'La consulta seleccionada no arroja datos.'}
				</Message>
			);
		}
  };

	const inventoryTypesOptions = useMemo(
    () =>
      inventoryTypesData?.data?.items.map(value => ({
        value: value.id,
        label: value.description,
      })),
    [inventoryTypesData?.data],
	);


	const { virtualArray, setViewRange, setExtraQuery } =
		useLazyPagination({
			trigger,
			result,
			initialLimit: 100,
			initialExtraQuery: {
				orderBy: 'createdAt:DESC',
				fromCreatedAt: defaultStartDate.toString(),
				toCreatedAt: defaultEndtDate.toString(),
			}
		});

    useEffect(() => {
      const unsubscribe = setExtraQuery(newValues => {
        setExtraQueryValues(newValues);
      });
  
      return () => unsubscribe
    }, [setExtraQuery]);

	const columns = useMemo<Column<Kardex>[]>(
		(): Column<Kardex>[] => [
			{
				id: 'product',
				Header: <FormattedMessage id="product" />,
				accessor: row => row?.product?.name,
				width: 280,
				maxWidth: 280,
				minWidth: 280,
				sticky: true,
				onToggleSortBy(kind, columnId) {
					setExtraQuery(value => ({
            ...value,
            orderBy: !kind
              ? undefined
              : `product.name:${kind.toUpperCase()}`,
          }));
					setExtraQueryValues(value => ({
            ...value,
            orderBy: !kind
              ? undefined
              : `product.name:${kind.toUpperCase()}`,
          }));
				},
				filter: (row, _, filterValue) => {
					filterValue = `${filterValue}`;

					setExtraQuery(value => ({
						...value,
						productName: filterValue.trim() ? filterValue : undefined,
					}));
					setExtraQueryValues(value => ({
						...value,
						productName: filterValue.trim() ? filterValue : undefined,
					}));
					return row;
				},
			},
			{
        id: 'locationName',
        Header: <FormattedMessage id="locationName" />,
        accessor: row => row.location?.name,
        minWidth: 150,
        width: 150,
        maxWidth: 150,
				sticky: true,
				onToggleSortBy(kind, columnId) {
					setExtraQuery(value => ({
            ...value,
            orderBy: !kind
              ? undefined
              : `location.name:${kind.toUpperCase()}`,
          }));
					setExtraQueryValues(value => ({
            ...value,
            orderBy: !kind
              ? undefined
              : `location.name:${kind.toUpperCase()}`,
          }));
				},
        Filter({ column }) {
          const { id, filterValue, setFilter } = column;

          return (
            <CheckPicker
              id={id}
              name={id}
              placeholder="-"
              size="xs"
              data={locationsOptions}
              block
              searchable={false}
              cleanable={false}
              value={filterValue || []}
              onChange={setFilter}
            />
          );
        },
        filter: (rows, _, filterValue) => {
          if ((filterValue?.length || 0) > 0) {
            setExtraQuery(value => ({
              ...value,
              locationName: filterValue.join(','),
            }));
            setExtraQueryValues(value => ({
              ...value,
              locationName: filterValue.join(','),
            }));
          } else {
            setExtraQuery(value => ({
              ...value,
              locationName: undefined,
            }));
            setExtraQueryValues(value => ({
              ...value,
              locationName: undefined,
            }));
          }

          return rows;
        },
			},
			{
        id: 'recordType',
        Header: <FormattedMessage id="recordType" />,
        accessor: row => row.recordType?.description,
        minWidth: 150,
        width: 150,
        maxWidth: 150,
				sticky: true,
				onToggleSortBy(kind, columnId) {
					setExtraQuery(value => ({
            ...value,
            orderBy: !kind
              ? undefined
              : `recordType:${kind.toUpperCase()}`,
          }));
					setExtraQueryValues(value => ({
            ...value,
            orderBy: !kind
              ? undefined
              : `recordType:${kind.toUpperCase()}`,
          }));
				},
        Filter({ column }) {
          const { id, filterValue, setFilter } = column;

          return (
            <CheckPicker
              id={id}
              name={id}
              placeholder="-"
              size="xs"
              data={inventoryTypesOptions}
              block
              searchable={false}
              cleanable={false}
              value={filterValue || []}
              onChange={setFilter}
            />
          );
        },
        filter: (rows, _, filterValue) => {
          if ((filterValue?.length || 0) > 0) {
            setExtraQuery(value => ({
              ...value,
              recordType: filterValue.join(','),
            }));
            setExtraQueryValues(value => ({
              ...value,
              recordType: filterValue.join(','),
            }));
          } else {
            setExtraQuery(value => ({
              ...value,
              recordType: undefined,
            }));
            setExtraQueryValues(value => ({
              ...value,
              recordType: undefined,
            }));
          }

          return rows;
        },
			},
			{
				id: 'quantity',
				Header: <FormattedMessage id="quantity" />,
				accessor: row => row.quantity,
				width: 80,
				maxWidth: 100,
				minWidth: 100,
				sticky: true,
				onToggleSortBy(kind, columnId) {
					setExtraQuery(value => ({
            ...value,
            orderBy: !kind
              ? undefined
              : `quantity:${kind.toUpperCase()}`,
          }));
					setExtraQueryValues(value => ({
            ...value,
            orderBy: !kind
              ? undefined
              : `quantity:${kind.toUpperCase()}`,
          }));
				},
				Cell: ({ value }) => {
					return (
					<div className="font-light text-center">
						<HighlightText text={value} />
					</div>
					)
				},
				filter: (row, _, filterValue) => {
					filterValue = `${filterValue}`;

					setExtraQuery(value => ({
						...value,
						quantity: filterValue ? parseFloat(filterValue) : undefined,
					}));
					setExtraQueryValues(value => ({
						...value,
						quantity: filterValue ? parseFloat(filterValue) : undefined,
					}));
					return row;
				},
			},
			{
				id: 'user',
				Header: <FormattedMessage id="user" />,
				accessor: row => row?.user?.name,
				width: 280,
				maxWidth: 280,
				minWidth: 280,
				sticky: true,
				onToggleSortBy(kind, columnId) {
					setExtraQuery(value => ({
            ...value,
            orderBy: !kind
              ? undefined
              : `user.name:${kind.toUpperCase()}`,
          }));
					setExtraQueryValues(value => ({
            ...value,
            orderBy: !kind
              ? undefined
              : `user.name:${kind.toUpperCase()}`,
          }));
				},
				filter: (row, _, filterValue) => {
					filterValue = `${filterValue}`;

					setExtraQuery(value => ({
						...value,
						userName: filterValue.trim() ? filterValue : undefined,
					}));
					setExtraQueryValues(value => ({
						...value,
						userName: filterValue.trim() ? filterValue : undefined,
					}));
					return row;
				},
			},
			{
				id: 'createdAt',
				Header: <FormattedMessage id="createdAt" />,
				accessor: row => row.createdAt,
				minWidth: 200,
				width: 250,
				maxWidth: 250,
				onToggleSortBy(kind, columnId) {
					setExtraQuery(value => ({
            ...value,
            orderBy: !kind
              ? undefined
              : `createdAt:${kind.toUpperCase()}`,
          }));
					setExtraQueryValues(value => ({
            ...value,
            orderBy: !kind
              ? undefined
              : `createdAt:${kind.toUpperCase()}`,
          }));
				},
				Cell: ({ value, inline, state }: CellProps<Kardex, Date>) => {
					if (value.toString() === "Invalid Date") {
						return null
					}
					const date = dayjs(value);
					if (inline) {
						return (
							<HighlightText
								text={dayjsCalendar(date)}
								subtext={state.globalFilter}
							/>
						);
					}

					return (
						<>
							<Whisper
								trigger={['click', 'hover', 'active']}
								placement="bottomStart"
								speaker={<Tooltip>{date.format(formats.datetime)}</Tooltip>}>
								<div className="text-sm font-light first-letter:capitalize">
									<HighlightText
										text={dayjsCalendar(date)}
										subtext={state.globalFilter}></HighlightText>
								</div>
							</Whisper>
						</>
					);
				},
				Filter({ column }) {
				const { id, filterValue, setFilter } = column;
				return (
					<DateRangePicker
						id={id}
						name={id}
						size="xs"
						block
						placement="auto"
						value={filterValue}
						defaultValue={[defaultStartDate, defaultEndtDate]}
						onChange={dates => {
							setFilter(dates);
						}}
						format="dd/MM/yyyy HH:mm:ss"
						ranges={dayjsRanges([
							'today',
							'tomorrow',
							'yesterday',
							'last7Days',
							'next7Days',
						])}
					/>
				);
			},
				filter: (rows, _, filterValue) => {
					setExtraQuery(value => ({
						...value,
						fromCreatedAt: filterValue?.[0],
						toCreatedAt: filterValue?.[1],
					}));
					setExtraQueryValues(value => ({
						...value,
						fromCreatedAt: filterValue?.[0],
						toCreatedAt: filterValue?.[1],
					}));

					return rows;
				},
			},
		], [locationsOptions, inventoryTypesOptions]
	)


	const rowContent = useCallback<DataGridRowContent<Kardex>>(
		(index, row, { viewport }) => {
			if (viewport.isWide) return;

			const cells = cellsById(row.cells);

			return (
				<div
          className={`flex flex-row justify-between items-stretch py-2 px-4 border-y -mb-px border-gray-200`}>
          <div className="flex flex-col flex-nowrap flex-1">
            <div className="mb-1 flex items-center font-normal text-gray-700">
							<span className="mb-0.5 text-lg font-normal text-gray-700">
								{cells.product.render('Cell')}
							</span>
							<span className="middle-dot w-1 h-1 mx-2 bg-current rounded" />
							<span className="mb-0.5 text-lg font-normal text-gray-700">
								{cells.locationName.render('Cell')}
							</span>
						</div>
						<div className="flex mb-0.5 text-gray-500">
							<span className="flex flex-row">
								{cells.recordType.render('Cell')}&nbsp;<span className="middle-dot w-1 h-1 mx-2 mt-2 bg-current rounded" />&nbsp;&nbsp;
								{cells.quantity.render('Cell')}&nbsp;unidades
							</span>
						</div>
						<div className="mb-1 flex items-center text-gray-500">
              <span className="mr-2">
								{cells.user.render('Cell')}
							</span>
              <span className="middle-dot w-1 h-1 mx-2 bg-current rounded" />
              <span className="flex flex-row">
                {cells.createdAt.render('Cell')}
              </span>
            </div>
          </div>
          
        </div>
			);
		},
		[],
	);

  const topToolbar = useCallback<DataGridToolbar<Kardex>>(
    () => {

      return (
        <>
          <IconButton
            className="mx-1 bg-gray-100 hover:shadow-md"
            size="sm"
            icon={<FaFileExcel className="rs-icon text-green-500" />}
            title="Exportar a csv"
            onClick={() => handleOnClickExportCSV()}></IconButton>
        </>
      );
    },
    [],
  );


	const globalFilter = useCallback<GlobalFilterCallback<Kardex>>(
		(rows, columnIds, filterValue: string) => {
			setExtraQuery(value => ({
				...value,
				keyword: filterValue.trim() ? filterValue : undefined,
			}));
			setExtraQueryValues(value => ({
				...value,
				keyword: filterValue.trim() ? filterValue : undefined,
			}));
			return rows;
		},
		[],
	);
	return (<div className="h-screen flex flex-col pb-1">
		<PageHeader title={<FormattedMessage id="kardex" />} />
		<DataGrid
			className="flex-1"
			data={virtualArray.ref}
			totalCount={virtualArray.ref.length}
			rangeChanged={setViewRange}
			columns={columns}
			selectable
			rowContent={rowContent}
      topToolbar={topToolbar}
			globalFilter={globalFilter}
			overscan={0}
			increaseViewportBy={700}
			autoResetFilters={false}
			autoResetSelectedRows={false}
			autoResetSortBy={false}
			loading={result.isFetching}
		/>
    {openExportData && (
      <ModalExportData
        title={<FormattedMessage id="export-data-orders" />}
        visible={openExportData}
        onClose={() => {
          setOpenExportData(false)
        }}
        submitExport={submitExport}
        disabled={virtualArray?.ref?.length > maxNumberDownloadAllowed}
        pages={virtualArray?.ref?.length / maxNumberDownloadAllowed}
      />
    )}
	</div>)
}
