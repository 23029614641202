import React from 'react';
import { Uploader, Button } from 'rsuite';
import '../../styles/General.css';
import { useIntl } from 'react-intl';
import { FaFileInvoiceDollar } from 'react-icons/fa';


export const FileUploader = ({fileHandler,fileInput,handleOpenFileBrowser,uploadedFileName, fileList}) => {
    const { $t } = useIntl();
    return(
      <Uploader  
          style={{ textAlign: '-webkit-center', border: '0px' }}
          action="//jsonplaceholder.typicode.com/posts/"
          draggable
          accept=".xls,.xlsx"
          onChange={fileHandler}
          autoUpload={false}
          ref={fileInput}
          onClick={handleOpenFileBrowser}
          multiple={false}
          name={uploadedFileName}
          fileList={fileList}
          defaultFileList={[]} 
          removable={true}
        >
        <div style={{ height: 200, alignItems: 'center', justifyContent: 'center', width: '56%', textAlignLast: 'center' }}>
          <FaFileInvoiceDollar className='text-4xl mt-2'/>
            <span>Arrastra tu archivo aquí</span>
            <div
              class="my-4 flex items-center before:mt-0.5 before:flex-1 before:border-t before:border-neutral-300 after:mt-0.5 after:flex-1 after:border-t after:border-neutral-300">
              <p
                  class="mx-4 mb-0 text-center font-semibold dark:text-neutral-200">
                  {$t({ id: 'route.or' })}
              </p>
              </div>
              <Button
                appearance="primary"
              //   disabled={!file ? true : false}
                className="mr-2"
              //   onClick={handleSubmit}
              >
                Selecciona el archivo
              </Button>
          </div>
        </Uploader>
    );   
}