import React from 'react';
import { Uploader } from 'rsuite';
import '../../styles/General.css';

export const FileUploader = ({fileHandler,fileInput,handleOpenFileBrowser,uploadedFileName, fileList}) => {
    return(
      <Uploader  
          style={{ textAlign: 'left', border: '0px' }}
          action="//jsonplaceholder.typicode.com/posts/"
          accept=".xls,.xlsx,.doc,.docx,.jpeg,.jpg,.png,.pdf"
          onChange={fileHandler}
          autoUpload={false}
          ref={fileInput}
          onClick={handleOpenFileBrowser}
          multiple={false}
          name={uploadedFileName}
          fileList={fileList}
          defaultFileList={[]} 
          removable={true}
        >
        <div style={{ paddingTop: '15px', paddingLeft: '5px' }}>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleOpenFileBrowser()
            }}
            style={{ textDecoration: 'underline', cursor: 'pointer', color: '#007bff' }}
          >
            Cargar archivo
          </a>
        </div>
        </Uploader>
    );   
}