import { GetInventoryRequest, Inventory } from "@api/query/inventoryApi/types";
import { useFindInventoryTypesQuery, useLazyGetInventoryQuery, useDownloadDataInventoryMutation } from "@api/query/inventoryApi/inventory";
import { DataGrid, DataGridRowContent, DataGridToolbar, GlobalFilterCallback, HighlightText, cellsById } from "@components/DataGrid";
import PageHeader from "@components/PageHeader";
import { useLazyPagination } from "@containers/PriceList/UseLazyPagination";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Close as CloseIcon, Edit as EditIcon } from '@rsuite/icons';
import { FormattedMessage } from "react-intl";
import { CellProps, Column } from "react-table";
import { CheckPicker, DateRangePicker, Tooltip, Whisper, Tag, IconButton, toaster, Message, Divider } from "rsuite";
import dayjs, { dayjsCalendar, dayjsRanges, formats } from "@utils/dayjs";
import { STATUS } from "./constants/inventory"
import { useDispatch, useSelector } from "react-redux";
import { setOpenAuthModal } from "@actions/index";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { getStatusColorInventory } from "@utils/DataFormat";
import { useFindLocationsQuery } from "@api/query/customerApi/customerApi";
import { ModalCreateEditInventory } from "./components/ModalCreateEditInventory";
import { ModalInventoryDetail } from "./components/ModalInventoryDetail";
import PlusIcon from '@rsuite/icons/Plus';
import * as constants from '../../utils/Constants';
import InfoIcon from '@rsuite/icons/InfoRound';
import { ModalProducts } from "./components/ModalProducts";
import UpdateIcon from '@rsuite/icons/UpdateRound';
import { FaFileExcel } from "react-icons/fa";
import { ModalExportData } from "@components/Modals/ModalExportData";
import { getMainLoggingParams } from "@selectors/SystemSelector";

export default function ListInventoryContainer() {
	const dispatch = useDispatch();
	const [openEditModal, setOpenEditModal] = useState(false);
	const [openDetailModal, setOpenDetail] = useState(false);
	const [openProductsModal, setOpenProducts] = useState(false);
  const [openExportData, setOpenExportData] = useState(false);
	const [inventoryData, setInventoryData] = useState({})
	const [triggerGetInventory, result] = useLazyGetInventoryQuery()
	const inventoryTypesData = useFindInventoryTypesQuery({ role: 'TRANSFER' });
  const { maxNumberDownloadAllowed } = useSelector(getMainLoggingParams);
  const [extraQueryValues, setExtraQueryValues] = useState({});

  const [setDownload] = useDownloadDataInventoryMutation();

	const trigger = useCallback(
		(value: GetInventoryRequest) => {
			return triggerGetInventory({
				...value,
			})
				.then(res => {
					if ((res?.error as FetchBaseQueryError)?.status === 401) {
						dispatch(setOpenAuthModal({ open: true }))
					}
				})
		},
		[triggerGetInventory],
	);

  const submitExport = (data) => {
		const body = { ...data, ...extraQueryValues }
		setDownload(body)
			.then(resp => {
			try {
				const menssageError = resp
				if (menssageError) {
					toaster.push(
						<Message
							type="success"
							showIcon
							closable
							duration={constants.NOTIFICATION_DURATION}
						>
							{'El archivo se está generando y se enviará un correo electrónico con un link de descarga.'}
						</Message>
					);
				}
			} catch (error) {
				console.error('Error en la promesa:', error);
			}
		})
  };

  const handleOnClickExportCSV = () => {
		if (virtualArray?.ref?.length > 0) {
			setOpenExportData(true)
		} else {
			toaster.push(
				<Message
					type="error"
					showIcon
					closable
					duration={constants.NOTIFICATION_DURATION}
				>
					{'La consulta seleccionada no arroja datos.'}
				</Message>
			);
		}
  };

	const defaultEndtDate = new Date();
	const defaultStartDate = new Date();
	defaultStartDate.setDate(defaultStartDate.getDate() - 30);
	
	const locationsData = useFindLocationsQuery({});

	const locationsOptions = useMemo(
    () =>
      locationsData?.data?.items.map(value => ({
        value: value.name,
        label: value.name,
      })),
    [locationsData?.data],
	);


	const { virtualArray, setViewRange, setExtraQuery } =
		useLazyPagination({
			trigger,
			result,
			initialLimit: 100,
			initialExtraQuery: {
				orderBy: 'updatedAt:DESC',
				fromUpdatedAt: defaultStartDate.toString(),
				toUpdatedAt: defaultEndtDate.toString(),
			}
		});
    

	const handleEditInventory = () => {
		setOpenEditModal(true)
	}
	const getInventoryProducts = (inventory: Inventory) => {
		setOpenProducts(true)
		setInventoryData(inventory)
	}

	const getInventoryDetails = (inventory: Inventory) => {
		setOpenDetail(true)
		setInventoryData(inventory)
	}

  useEffect(() => {
    const unsubscribe = setExtraQuery(newValues => {
      setExtraQueryValues(newValues);
    });

    return () => unsubscribe
  }, [setExtraQuery]);

	const columns = useMemo<Column<Inventory>[]>(
		(): Column<Inventory>[] => [
			{
				id: 'productName',
				Header: <FormattedMessage id="productName" />,
				accessor: row => row?.product?.name,
				width: 280,
				maxWidth: 280,
				minWidth: 280,
				sticky: true,
				onToggleSortBy(kind, columnId) {
					setExtraQuery(value => ({
            ...value,
            orderBy: !kind
              ? undefined
              : `product.name:${kind.toUpperCase()}`,
          }));
					setExtraQueryValues(value => ({
            ...value,
            orderBy: !kind
              ? undefined
              : `product.name:${kind.toUpperCase()}`,
          }));
				},
				filter: (row, _, filterValue) => {
					filterValue = `${filterValue}`;

					setExtraQuery(value => ({
						...value,
						productName: filterValue.trim() ? filterValue : undefined,
					}));
					setExtraQueryValues(value => ({
						...value,
						productName: filterValue.trim() ? filterValue : undefined,
					}));
					return row;
				},
			},
			{
        id: 'locationName',
        Header: <FormattedMessage id="locationName" />,
        accessor: row => row.location?.name,
        minWidth: 150,
        width: 150,
        maxWidth: 150,
				sticky: true,
				onToggleSortBy(kind, columnId) {
					setExtraQuery(value => ({
            ...value,
            orderBy: !kind
              ? undefined
              : `location.name:${kind.toUpperCase()}`,
          }));
					setExtraQueryValues(value => ({
            ...value,
            orderBy: !kind
              ? undefined
              : `location.name:${kind.toUpperCase()}`,
          }));
				},
        Filter({ column }) {
          const { id, filterValue, setFilter } = column;

          return (
            <CheckPicker
              id={id}
              name={id}
              placeholder="-"
              size="xs"
              data={locationsOptions}
              block
              searchable={false}
              cleanable={false}
              value={filterValue || []}
              onChange={setFilter}
            />
          );
        },
        filter: (rows, _, filterValue) => {
          if ((filterValue?.length || 0) > 0) {
            setExtraQuery(value => ({
              ...value,
              locationName: filterValue.join(','),
            }));
            setExtraQueryValues(value => ({
              ...value,
              locationName: filterValue.join(','),
            }));
          } else {
            setExtraQuery(value => ({
              ...value,
              locationName: undefined,
            }));
            setExtraQueryValues(value => ({
              ...value,
              locationName: undefined,
            }));
          }

          return rows;
        },
			},
			{
				id: 'totalQuantity',
				Header: <FormattedMessage id="totalQuantity" />,
				accessor: row => row.totalQuantity,
				width: 80,
				maxWidth: 100,
				minWidth: 100,
				sticky: true,
				onToggleSortBy(kind, columnId) {
					setExtraQuery(value => ({
            ...value,
            orderBy: !kind
              ? undefined
              : `totalQuantity:${kind.toUpperCase()}`,
          }));
					setExtraQueryValues(value => ({
            ...value,
            orderBy: !kind
              ? undefined
              : `totalQuantity:${kind.toUpperCase()}`,
          }));
				},
				Cell: ({ value }) => {
					return (
					<div className="font-light text-center">
						<HighlightText text={value} />
					</div>
					)
				},
				filter: (row, _, filterValue) => {
					filterValue = `${filterValue}`;

					setExtraQuery(value => ({
						...value,
						totalQuantity: filterValue ? parseFloat(filterValue) : undefined,
					}));
					setExtraQueryValues(value => ({
						...value,
						totalQuantity: filterValue ? parseFloat(filterValue) : undefined,
					}));
					return row;
				},
			},
			{
				id: 'reservedQuantity',
				Header: <FormattedMessage id="reservedQuantity" />,
				accessor: row => row.reservedQuantity,
				width: 80,
				maxWidth: 100,
				minWidth: 100,
				sticky: true,
				onToggleSortBy(kind, columnId) {
					setExtraQuery(value => ({
            ...value,
            orderBy: !kind
              ? undefined
              : `reservedQuantity:${kind.toUpperCase()}`,
          }));
					setExtraQueryValues(value => ({
            ...value,
            orderBy: !kind
              ? undefined
              : `reservedQuantity:${kind.toUpperCase()}`,
          }));
				},
				Cell: ({ value }) => {
					return (
					<div className="font-light text-center">
						<HighlightText text={value} />
					</div>
					)
				},
				filter: (row, _, filterValue) => {
					filterValue = `${filterValue}`;

					setExtraQuery(value => ({
						...value,
						reservedQuantity: filterValue ? parseFloat(filterValue) : undefined,
					}));
					setExtraQueryValues(value => ({
						...value,
						reservedQuantity: filterValue ? parseFloat(filterValue) : undefined,
					}));
					return row;
				},
			},
			{
				id: 'availableQuantity',
				Header: <FormattedMessage id="availableQuantity" />,
				accessor: row => row.availableQuantity,
				width: 80,
				maxWidth: 100,
				minWidth: 100,
				sticky: true,
				onToggleSortBy(kind, columnId) {
					setExtraQuery(value => ({
            ...value,
            orderBy: !kind
              ? undefined
              : `availableQuantity:${kind.toUpperCase()}`,
          }));
					setExtraQueryValues(value => ({
            ...value,
            orderBy: !kind
              ? undefined
              : `availableQuantity:${kind.toUpperCase()}`,
          }));
				},
				Cell: ({ value }) => {
					return (
					<div className="font-light text-center">
						<HighlightText text={value} />
					</div>
					)
				},
				filter: (row, _, filterValue) => {
					filterValue = `${filterValue}`;

					setExtraQuery(value => ({
						...value,
						availableQuantity: filterValue ? parseFloat(filterValue) : undefined,
					}));
					setExtraQueryValues(value => ({
						...value,
						availableQuantity: filterValue ? parseFloat(filterValue) : undefined,
					}));
					return row;
				},
			},
			{
        id: 'status',
        Header: <FormattedMessage id="state" />,
        accessor: row => row.status,
        minWidth: 70,
        width: 70,
        maxWidth: 70,
				sticky: true,
				onToggleSortBy(kind, columnId) {
					setExtraQuery(value => ({
            ...value,
            orderBy: !kind
              ? undefined
              : `status:${kind.toUpperCase()}`,
          }));
					setExtraQueryValues(value => ({
            ...value,
            orderBy: !kind
              ? undefined
              : `status:${kind.toUpperCase()}`,
          }));
				},
				Cell: ({ value, state, row }) => {
					return (
						<Tag
							color={getStatusColorInventory(
								row.original?.status,
								row.original?.warning,
								row.original?.availableQuantity,
							)}
							size="sm">
							<HighlightText text={value} subtext={state.globalFilter} />
						</Tag>
					)
				},
        Filter({ column }) {
          const { id, filterValue, setFilter } = column;

          return (
            <CheckPicker
              id={id}
              name={id}
              placeholder="-"
              size="xs"
              data={STATUS}
              block
              searchable={false}
              cleanable={false}
              value={filterValue || []}
              onChange={setFilter}
            />
          );
        },
        filter: (rows, _, filterValue) => {
          if ((filterValue?.length || 0) > 0) {
            setExtraQuery(value => ({
              ...value,
              status: filterValue.join(','),
            }));
            setExtraQueryValues(value => ({
              ...value,
              status: filterValue.join(','),
            }));
          } else {
            setExtraQuery(value => ({
              ...value,
              status: undefined,
            }));
            setExtraQueryValues(value => ({
              ...value,
              status: undefined,
            }));
          }

          return rows;
        },
			},
			{
				id: 'updatedAt',
				Header: <FormattedMessage id="updatedAt" />,
				accessor: row => row.updatedAt,
				minWidth: 200,
				width: 250,
				maxWidth: 250,
				onToggleSortBy(kind, columnId) {
					setExtraQuery(value => ({
            ...value,
            orderBy: !kind
              ? undefined
              : `updatedAt:${kind.toUpperCase()}`,
          }));
					setExtraQueryValues(value => ({
            ...value,
            orderBy: !kind
              ? undefined
              : `updatedAt:${kind.toUpperCase()}`,
          }));
				},
				Cell: ({ value, inline, state }: CellProps<Inventory, Date>) => {
					if (value.toString() === "Invalid Date") {
						return null
					}
					const date = dayjs(value);
					if (inline) {
						return (
							<HighlightText
								text={dayjsCalendar(date)}
								subtext={state.globalFilter}
							/>
						);
					}

					return (
						<>
							<Whisper
								trigger={['click', 'hover', 'active']}
								placement="bottomStart"
								speaker={<Tooltip>{date.format(formats.datetime)}</Tooltip>}>
								<div className="text-sm font-light first-letter:capitalize">
									<HighlightText
										text={dayjsCalendar(date)}
										subtext={state.globalFilter}></HighlightText>
								</div>
							</Whisper>
						</>
					);
				},
				Filter({ column }) {
				const { id, filterValue, setFilter } = column;
				return (
					<DateRangePicker
						id={id}
						name={id}
						size="xs"
						block
						placement="auto"
						value={filterValue}
						defaultValue={[defaultStartDate, defaultEndtDate]}
						onChange={dates => {
							setFilter(dates);
						}}
						format="dd/MM/yyyy HH:mm:ss"
						ranges={dayjsRanges([
							'today',
							'tomorrow',
							'yesterday',
							'last7Days',
							'next7Days',
						])}
						// disabledDate={date =>
						// 	dayjs(date).isBefore(dayjs().subtract(15, 'days'), 'day')
						// }
					/>
				);
			},
				filter: (rows, _, filterValue) => {
					setExtraQuery(value => ({
						...value,
						fromUpdatedAt: filterValue?.[0],
						toUpdatedAt: filterValue?.[1],
					}));
					setExtraQueryValues(value => ({
						...value,
						fromUpdatedAt: filterValue?.[0],
						toUpdatedAt: filterValue?.[1],
					}));

					return rows;
				},
			},
			{
				id: 'actions',
				Header: <FormattedMessage id="action" />,
				width: 100,
				maxWidth: 100,
				minWidth: 100,
				sticky: true,
				onToggleSortBy(kind, columnId) {
					setExtraQuery(value => ({
						...value,
						orderBy: !kind
					}));
					setExtraQueryValues(value => ({
						...value,
						orderBy: !kind
					}));
				},
				Cell: ({ value, row }) => {
					const id = row?.original?.id
					return (
						<div>
							<IconButton
                title="Ver últimos movimientos"
                size="sm"
								icon={<InfoIcon />}
								onClick={() => {
							if (!openDetailModal) {
									getInventoryDetails(row.original)
								}
							}}
              />
							<IconButton
                title="Productos"
                size="sm"
								icon={<UpdateIcon />}
								onClick={() => {
							if (!openProductsModal) {
									getInventoryProducts(row.original)
								}
							}}
              />
						</div>
					)

				}
				,
				filter: (row, _, filterValue) => {
					filterValue = `${filterValue}`;
					setExtraQuery(value => ({
						...value,
						actions: filterValue.trim() ? filterValue : undefined,
					}));
					setExtraQueryValues(value => ({
						...value,
						actions: filterValue.trim() ? filterValue : undefined,
					}));
					return row;
				},
			},
		], [locationsOptions]
	)


	const rowContent = useCallback<DataGridRowContent<Inventory>>(
		(index, row, { viewport }) => {
			if (viewport.isWide) return;

			const cells = cellsById(row.cells);
			return (
				<div
					className={`flex flex-row justify-between items-stretch py-2 px-4 -mb-px `}>
					<div className="flex flex-col flex-nowrap flex-1">
						<div className="mb-1 flex items-center font-normal text-gray-700">
							<span className="mb-0 text-lg font-normal text-gray-700 leading-none" style={{ fontSize: '16px' }}>
								{cells.productName.render('Cell')}
							</span>
						</div>
						<div className="mb-1 flex items-center font-normal text-gray-700">
							<span className="mb-0 text-lg font-normal text-gray-700 leading-none " style={{ fontSize: '16px' }}>
								{cells.locationName.render('Cell')}
							</span>
						</div>
						<div className="mb-1 flex items-center font-normal text-gray-700">
							<span className="text-sm">
								{cells.updatedAt.render('Cell')}
							</span>
						</div>
						<div className="mb-0.5 text-gray-700 w-full">
							<div className="flex mb-0.5 text-gray-700 ">
								<span className="flex flex-row w-1/2 ml-2 p-1">
									<FormattedMessage id="totalQuantity" />
								</span>
								<span className="flex flex-row justify-end w-1/2 mr-2 p-1">
									{cells.totalQuantity.render('Cell')}
								</span>
							</div>
							<div className="flex mb-0.5 text-gray-700 ">
								<span className="flex flex-row w-1/2 ml-2 p-1">
									<FormattedMessage id="availableQuantity" />
								</span>
								<span className="flex flex-row justify-end w-1/2 mr-2 p-1">
									{cells.availableQuantity.render('Cell')}
								</span>
							</div>
							<div className="flex mb-0.5 text-gray-700 ">
								<span className="flex flex-row w-1/2 ml-2 p-1">
									<FormattedMessage id="reservedQuantity" />
								</span>
								<span className="flex flex-row justify-end w-1/2 mr-2 p-1">
									{cells.reservedQuantity.render('Cell')}
								</span>
							</div>
							<div className="flex mb-0.5 text-gray-700 border-b">
								<span className="ml-2">{cells.actions.render('Cell')}</span>
							</div>
						</div>
					</div>
				</div>
			);
		},
		[],
	);
	
		const topToolbar = useCallback<DataGridToolbar<Inventory>>(
    () => {

      return (
        <>
          <IconButton
            className="ml-auto mx-1 bg-gray-100 hover:shadow-md"
            size="sm"
            icon={<PlusIcon />}
            title="Atualizar inventario orden"
            onClick={handleEditInventory}
          />
          <Divider vertical className="rs-divider-vertical" />
          <IconButton
            className="mx-1 bg-gray-100 hover:shadow-md"
            size="sm"
            icon={<FaFileExcel className="rs-icon text-green-500" />}
            title="Exportar a csv"
            onClick={() => handleOnClickExportCSV()}></IconButton>
          <IconButton></IconButton>
        </>
      );
    },
    [],
  );

	const globalFilter = useCallback<GlobalFilterCallback<Inventory>>(
		(rows, columnIds, filterValue: string) => {
			setExtraQuery(value => ({
				...value,
				keyword: filterValue.trim() ? filterValue : undefined,
			}));
			setExtraQueryValues(value => ({
				...value,
				keyword: filterValue.trim() ? filterValue : undefined,
			}));
			return rows;
		},
		[],
	);
	return (<div className="h-screen flex flex-col pb-1">
		<PageHeader title={<FormattedMessage id="inventory-consult" />} />
		<DataGrid
			className="flex-1"
			data={virtualArray.ref}
			totalCount={virtualArray.ref.length}
			rangeChanged={setViewRange}
			columns={columns}
			selectable
			rowContent={rowContent}
			globalFilter={globalFilter}
			topToolbar={topToolbar}
			overscan={0}
			increaseViewportBy={700}
			autoResetFilters={false}
			autoResetSelectedRows={false}
			autoResetSortBy={false}
			loading={result.isFetching}
		/>
    {openExportData && (
      <ModalExportData
        title={<FormattedMessage id="export-data-orders" />}
        visible={openExportData}
        onClose={() => {
          setOpenExportData(false)
        }}
        submitExport={submitExport}
        disabled={virtualArray?.ref?.length > maxNumberDownloadAllowed}
        pages={virtualArray?.ref?.length / maxNumberDownloadAllowed}
      />
    )}
		{openEditModal && (
			<ModalCreateEditInventory
				title={<FormattedMessage id={"update-inventory"} />}
				visible={openEditModal} 
				locationsOptions={locationsOptions} 
				onClose={() => {
					setOpenEditModal(false)
				}}
			/>
		)}
		{openDetailModal && (
			<ModalInventoryDetail
				inventoryInfo={inventoryData}
				title={<FormattedMessage id={"latest-movements"} />}
				visible={openDetailModal} 
				onClose={() => {
					setOpenDetail(false)
				}}
			/>
		)}
		{openProductsModal && (
			<ModalProducts
				inventoryInfo={inventoryData}
				visible={openProductsModal} 
				inventoryTypesData={inventoryTypesData} 
				onClose={() => {
					setOpenProducts(false)
				}}
			/>
		)}
	</div>)
}
