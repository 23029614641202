export const DEFAULT_PRODUCT_DATA = {
  name: '',
  title: '',
  notes: '',
  priceBeforeTax: '',
  tax: '',
  priceAfterTax: '',
  unit: ',',
  sku: '.',
  active: '',
}