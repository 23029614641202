import React from 'react';

import {
  Form,
  Panel,
  TagPicker,
} from 'rsuite';


type TagsProps = {
  className?: string;
  tagsList;
  selectedTags;
  onTagChanged;
}
export const Tags: React.FC<TagsProps> = ({
  className,
  tagsList,
  selectedTags,
  onTagChanged,
}) => {
  return (
    <Panel
      className={className}
      header={
        <div>
          <span className="font-medium">Etiquetas</span>
        </div>
      }>
      <Form
        fluid
      >
        <TagPicker
          data={tagsList}
          block
          onChange={onTagChanged}
          value={selectedTags}
          placement="topStart"
          cleanable={false}
        />
      </Form>
    </Panel>
  );
};
