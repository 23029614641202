import { createApi } from '@reduxjs/toolkit/query/react';
import { ordoxBaseQuery } from '../baseQuery';
import { GetInventoryRequest, GetInventoryResponse, InventoryTypesResponse, GetKardexResponse, CreateInventoryRequest, GetRecordsTypes, GetChannelsResponse, Channel, UpdateChannelRequest, UpdateSubscribersRequest } from './types';
import { Inventory } from '../InventoryApi';
import queryString from 'query-string';

export const inventory = createApi({
  reducerPath: 'inventory',
  tagTypes: ['inventory'],
  baseQuery: ordoxBaseQuery(),
  endpoints: builder => ({
    getInventory: builder.query({
      providesTags: ['inventory'],
      query: (data?: GetInventoryRequest) => {
        return {
          params: data,
          url: 'inventory',
          method: 'GET'
        };
      },
      transformResponse: (data: GetInventoryResponse, _, request) => {
        return {
          limit: request.limit,
          offset: request.offset,
          total: data.total,
          items: data.items,
        };
      },
    }),
    getKardex: builder.query({
      providesTags: ['inventory'],
      query: (data?: GetInventoryRequest) => {
        return {
          params: data,
          url: 'inventory-records',
          method: 'GET'
        };
      },
      transformResponse: (data: GetKardexResponse, _, request) => {
        return {
          limit: request.limit,
          offset: request.offset,
          total: data.total,
          items: data.items,
        };
      },
    }),
    getChannels: builder.query({
      providesTags: ['inventory'],
      query: (data?: GetInventoryRequest) => {
        return {
          params: data,
          url: 'channels',
          method: 'GET'
        };
      },
      transformResponse: (data: GetChannelsResponse, _, request) => {
        return {
          limit: request.limit,
          offset: request.offset,
          total: data.total,
          items: data.items,
        };
      },
    }),
    createChannel: builder.mutation<Channel, Channel>({
      invalidatesTags: ['inventory'],
      query: data => ({
        url: 'channels',
        method: 'POST',
        body: data,
      }),
      transformResponse(baseQueryReturnValue, meta, arg): Channel {
        return baseQueryReturnValue as Channel
      },
    }),
    updateChannel: builder.mutation<Channel, UpdateChannelRequest>({
      invalidatesTags: ['inventory'],
      query: ({ id, data }) => {
        return {
          url: `/channels/${id}`,
          method: 'PATCH',
          body: data,
        }
      },
    }),
    updateSubscribers: builder.mutation<Channel, UpdateSubscribersRequest>({
      invalidatesTags: ['inventory'],
      query: ({ id, subscribers }) => {
        return {
          url: `/channels/${id}/subscribers`,
          method: 'PATCH',
          body: subscribers,
        }
      },
    }),
    findInventoryTypes: builder.query<InventoryTypesResponse, {}>({
      providesTags: ['inventory'],
      query: (data?: GetRecordsTypes) => ({
        params: data,
        url: `/inventory-record-types/`,
        method: 'GET'
      }),
    }),
    createInventory: builder.mutation<Inventory, CreateInventoryRequest>({
      invalidatesTags: ['inventory'],
      query: data => ({
        url: '/inventory',
        method: 'POST',
        body: data,
      }),
    }),
    createInventoryTransfer: builder.mutation<Inventory, CreateInventoryRequest>({
      invalidatesTags: ['inventory'],
      query: data => ({
        url: '/inventory/transfer',
        method: 'POST',
        body: data,
      }),
    }),
    findInventory: builder.query({
      providesTags: ['inventory'],
      query: (filters) => {
        const params = queryString.stringify(filters)
        return {
          url: `inventory${(params && `?${params}`) || ''}`,
          method: 'GET'
        }
      },
    }),
    downloadDataInventory: builder.mutation<Inventory, CreateInventoryRequest>({
      invalidatesTags: ['inventory'],
      query: data => ({
        url: '/inventory/downloads',
        method: 'POST',
        body: data,
      }),
    }),
    downloadDataInventoryRecords: builder.mutation<Inventory, CreateInventoryRequest>({
      invalidatesTags: ['inventory'],
      query: data => ({
        url: '/inventory-records/downloads',
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const {
  useGetInventoryQuery,
  useCreateInventoryMutation,
  useCreateInventoryTransferMutation,
  useFindInventoryTypesQuery,
  useLazyGetKardexQuery,
  useLazyGetChannelsQuery,
  useLazyFindInventoryQuery,
  useGetKardexQuery,
  useGetChannelsQuery,
  useLazyGetInventoryQuery,
  useUpdateChannelMutation,
  useUpdateSubscribersMutation,
  useCreateChannelMutation,
  useDownloadDataInventoryMutation,
  useDownloadDataInventoryRecordsMutation,
} = inventory;

export default inventory;
