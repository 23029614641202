import { formatDateAndHourSpanishCO } from "@utils/DataFormat";
import React from "react";
import { FaUserAlt } from "react-icons/fa";
import { IconButton, Timeline } from "rsuite";
import "../styles/OrderTracking.css";

const OrderTracking = ({ trackingInfo }) => {
  return (
    <div className="buffer-order-tracking">
      <Timeline>
        {trackingInfo.map((event) => {
          return (
            <Timeline.Item>
              {" "}
              {event.status.description + " "}
              {formatDateAndHourSpanishCO(event.createdAt)}
              <p>
                <IconButton
                  className="mx-1 bg-gray-100 hover:shadow-md"
                  size="xs"
                  icon={<FaUserAlt className="rs-icon text-black-200" />}
                />{" "}
                {event.user.fullName}
              </p>
              {event?.content && (
                <span>
                  <a
                    href={event?.content}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'underline', cursor: 'pointer', color: '#007bff' }}
                  >
                    Ver
                  </a> archivo
                </span>
              )}
              {event.notes && <p>{event.notes}</p>}
            </Timeline.Item>
          );
        })}
      </Timeline>
    </div>
  );
};

export default OrderTracking;
