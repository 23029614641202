import { InvoiceLink, CreditNoteLink, roundResult } from '@utils/configERP';
import React from 'react';
import { Notification } from 'rsuite';
import {
  GET_ALEGRA_PURCHASE_ORDER,
  GET_SHOPIFY_LINK,
  GET_WOOCOMMERCE_LINK,
} from '../../utils/Constants';
import { formatCurrencySpanishCO } from '@utils/DataFormat';

const GeneralInfo = ({ order, ecomSite }) => (
  <div>
    <div>
      <b>Total orden: </b> {formatCurrencySpanishCO(
        roundResult(order.totalAfterTax)
      )}
    </div>
    <div>
      <b>Total unidades:  </b> {order.totalUnits}
    </div>
    <div>
      <b>Canal de venta: </b> {order.channel.description}
    </div>

    <div>
      <b>Facturación: </b> {order.invoicingSettings.description}
    </div>

    {(order.invoiceId !== 0 || order.purchaseOrderId !== 0) && (
      <div>
        {order.invoiceId && <InvoiceLink order={order} />}
        {order.creditNotes.length > 0 && <CreditNoteLink order={order} />}
        {order.purchaseOrderId && (
          <div>
            <b>Remisión: </b>
            <a
              href={GET_ALEGRA_PURCHASE_ORDER(order.purchaseOrderIdERP)}
              className="stretched-link"
              target="_blank"
            >
              {order.purchaseOrderId}
            </a>
          </div>
        )}
        {order?.orderNumberShopify && (
          <div>
            <b>Shopify #: </b>
            <a
              href={GET_SHOPIFY_LINK(ecomSite, order?.idShopify)}
              className="stretched-link"
              target="_blank"
            >
              {order.orderNumberShopify}
            </a>
          </div>
        )}
        {order?.orderNumberWoocommerce && (
          <div>
            <b>WooCommerce #: </b>
            <a
              href={GET_WOOCOMMERCE_LINK(ecomSite, order?.idWoocommerce)}
              className="stretched-link"
              target="_blank"
            >
              {order.orderNumberWoocommerce}
            </a>
          </div>
        )}
      </div>
    )}
    <div className='pt-4'>
    {order?.comments?.trim() && (
      <Notification className='w-72 ml-0' closable type="info" header="Comentarios">
        <p>{order?.comments}</p>
      </Notification>
    )}
    {order?.notes?.trim() && (
      <Notification className='w-72 ml-0' closable type="info" header="Notas">
        <p>{order?.notes}</p>
      </Notification>
    )}
    {order?.incomingPurchaseOrder?.trim() && (
      <Notification className='w-72 ml-0' closable type="info" header="Orden de compra entrante">
        <p>{order?.incomingPurchaseOrder}</p>
      </Notification>
    )}
    </div>
  </div>
);

export default GeneralInfo;
