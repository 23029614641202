import FormattedNumber from '@components/ui/FormattedNumber';
import { Icon } from '@rsuite/icons';
import ArrowUpLineIcon from '@rsuite/icons/ArrowUpLine';
import CloseIcon from '@rsuite/icons/Close';
import SearchIcon from '@rsuite/icons/Search';
import useViewport, { UseViewportInstance } from '@utils/useViewport';
import { styled } from 'goober';
import { noop } from 'lodash';
import React, {
  useCallback,
  useDeferredValue,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { BiFilter } from 'react-icons/bi';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Cell,
  Column,
  ColumnInstance,
  Hooks,
  IdType,
  Row,
  TableState,
  TableToggleCommonProps,
  useFilters,
  useGlobalFilter,
  useRowSelect,
  useSortBy,
  useTable,
  UseTableOptions,
} from 'react-table';
import {
  ItemContent,
  TableComponents,
  TableVirtuoso,
  TableVirtuosoProps,
} from 'react-virtuoso';
import { useKeyBindings, useToggle } from 'rooks';
import {
  Checkbox,
  CheckboxProps,
  Drawer,
  IconButton,
  Input,
  InputGroup,
  Loader,
} from 'rsuite';
import { mergeStyle } from '../ui/mergeStyle';
import { useRowSelection } from './useRowSelection';

// plugin augmentation
declare module 'react-table' {
  export interface TableOptions<D extends object = {}>
    extends UseGlobalFiltersOptions<D>,
      UseRowSelectOptions<D>,
      UseFiltersOptions<D>,
      UseSortByOptions<D> {}

  export interface TableInstance<D extends object = {}>
    extends UseFiltersInstanceProps<D>,
      UseGlobalFiltersInstanceProps<D>,
      UseSortByInstanceProps<D>,
      UseRowSelectInstanceProps<D> {
    [key: string]: any;
  }

  export interface ColumnInstance<D extends object = {}>
    extends UseFiltersColumnProps<D>,
      UseSortByColumnProps<D>,
      UseRowSelectInstanceProps<D> {}

  export interface ColumnInterface<D extends object = {}>
    extends UseGlobalFiltersColumnOptions<D>,
      UseSortByColumnOptions<D>,
      UseFiltersColumnOptions<D>,
      UseRowSelectOptions<D> {
    sticky?: boolean;
    className?: string;
    onToggleSortBy?: (kind: 'asc' | 'desc' | false, columnId: string) => void;
  }

  export interface Row<D extends object = {}> extends UseRowSelectRowProps<D> {}

  export interface TableState<D extends object>
    extends UseGlobalFiltersState<D>,
      UseSortByState<D>,
      UseRowSelectState<D> {}
}

export type RecordAny = Record<PropertyKey, any>;

export type HighlightTextProps = {
  text?: string | number;
  subtext?: string | number;
};

export const HighlightText: React.FC<HighlightTextProps> = ({
  text,
  subtext,
}) => {
  if (text === null || text === undefined) return null;
  if (subtext === null || subtext === undefined) return <>{text}</>;

  text = `${text}`;
  subtext = `${subtext}`;

  const startIndex = text
    .toLocaleLowerCase()
    .indexOf(subtext.toLocaleLowerCase());

  if (startIndex === -1) return <>{text}</>;

  const endIndex = startIndex + subtext.length;

  return (
    <>
      {text.slice(0, startIndex)}
      <span className="rounded-sm bg-blue-100 text-blue-500">
        {text.slice(startIndex, endIndex)}
      </span>
      {text.slice(endIndex)}
    </>
  );
};

const DrawerStyled = styled(Drawer)`
  .rs-drawer-content {
    position: relative;
  }
`;

export type GlobalFilterProps = {
  setGlobalFilter(value: string): void;
  globalFilter: string;
};

export type GlobalFilterCallback<D extends RecordAny> = (
  rows: Array<Row<D>>,
  columnIds: Array<IdType<D>>,
  filterValue: any,
) => Array<Row<D>>;

const GlobalFilter: React.FC<GlobalFilterProps> = ({
  globalFilter,
  setGlobalFilter,
}) => {
  const [value, setValue] = useState(globalFilter);
  const deferredValue = useDeferredValue(value);

  const { $t } = useIntl();

  useEffect(() => {
    if (deferredValue) {
      if (deferredValue.length >= 3) {
        setGlobalFilter(deferredValue);
      }
    } else {
      setGlobalFilter(deferredValue);
    }
  }, [deferredValue]);

  return (
    <InputGroup className="max-w-lg" inside size="sm">
      <InputGroup.Addon>
        <SearchIcon />
      </InputGroup.Addon>
      <Input
        type="search"
        size="sm"
        placeholder={$t({ id: 'search' })}
        value={value || ''}
        onChange={setValue}
        autoComplete="off"
        inputMode="search"
        autoCorrect="off"
      />
      {value && (
        <InputGroup.Button onClick={() => setValue('')}>
          <CloseIcon />
        </InputGroup.Button>
      )}
    </InputGroup>
  );
};

const SelectRowCheckbox: React.FCC<
  TableToggleCommonProps & { name?: string }
> = ({ name, children, onChange, ...props }) => {
  const onCheckboxChange: CheckboxProps['onChange'] = useCallback(
    (_0, _1, ev) => {
      onChange?.(ev);
    },
    [onChange],
  );

  return (
    <Checkbox name={name} {...props} onChange={onCheckboxChange}>
      {children}
    </Checkbox>
  );
};

const makeSelectionColumn = <D extends RecordAny>(hooks: Hooks<D>) => {
  hooks.visibleColumns.push(columns => [
    {
      id: '$rowSelect',
      maxWidth: 35,
      width: 35,
      disableFilters: true,
      disableGlobalFilter: true,
      disableSortBy: true,
      sticky: true,
      left: 0,
      Header: ({ getToggleAllRowsSelectedProps }) => (
        <SelectRowCheckbox
          {...mergeStyle(getToggleAllRowsSelectedProps(), {
            className: '-m-2 mt-2 -ml-1',
          })}
        />
      ),
      Cell: ({ row }) => (
        <SelectRowCheckbox
          {...mergeStyle(row.getToggleRowSelectedProps(), {
            className: '-m-2 ml-0',
          })}
        />
      ),
    },
    ...columns,
  ]);
};

function stopPropagation<E extends React.SyntheticEvent<any>>(e: E) {
  e.stopPropagation();
}

const defaultColumn: Partial<Column<any>> = {
  minWidth: undefined,
  maxWidth: undefined,
  width: undefined,
  disableFilters: false,
  disableGlobalFilter: false,
  Cell: ({ state, value, column }) => {
    const { filterValue } = column;
    const { globalFilter } = state;

    return <HighlightText text={value} subtext={filterValue || globalFilter} />;
  },
  Filter({ column }) {
    const { id, filterValue, setFilter } = column;

    return (
      <InputGroup size="xs" inside>
        <Input
          id={id}
          name={id}
          value={filterValue || ''}
          onChange={setFilter}
        />
      </InputGroup>
    );
  },
};

export function cellsById<D extends RecordAny>(
  cells: Cell<D>[],
): Record<string, Cell<D>> {
  return cells.reduce(
    (record, cell) => Object.assign(record, { [cell.column.id]: cell }),
    {},
  );
}

function toggleFn(state: boolean, action: any) {
  if (typeof action === 'boolean') return action;
  return !state;
}

export type DataGridContext<D extends RecordAny> = {
  viewport: UseViewportInstance;
  rows: Row<D>[];
  rowsById: Record<string, Row<D>>;
  selectable: boolean;
  selectedFlatRows: Row<D>[];
  selectedData: Record<string | number, D>;
  toggleAllRowsSelected: (value: boolean) => void;
  filtersVisible: boolean;
  filteredFlatRows: Row<D>[];
  columns: ColumnInstance<D>[];
};

export type DataGridRowContent<D extends RecordAny> = (
  index: number,
  row: Row<D>,
  context: DataGridContext<D>,
) => React.ReactNode;

export type DataGridToolbar<D extends RecordAny> = (
  context: DataGridContext<D>,
) => React.ReactNode;

export type DataGridRowClickHandler<D extends RecordAny> = (
  row: Row<D>,
  cell: Cell<D>,
  ev: React.MouseEvent<HTMLElement, MouseEvent>,
) => void;

export type DataGridRowContextMenu<D extends RecordAny> = (
  row: Row<D>,
  cell: Cell<D>,
) => void;

export type DataGridProps<D extends RecordAny> = TableVirtuosoProps<
  unknown,
  DataGridContext<D>
> & {
  totalCount?: number;
  data: ReadonlyArray<D>;
  columns: ReadonlyArray<Column<D>>;
  disableGlobalFilter?: boolean;
  globalFilter?: GlobalFilterCallback<D>;
  disableFilters?: boolean;
  topToolbar?: DataGridToolbar<D>;
  selectable?: boolean;
  rowContent?: DataGridRowContent<D>;
  rowContextMenu?: DataGridRowContextMenu<D>;
  initialState?: Partial<TableState<D>>;
  onSelectedRowsChange?: (rows: Row<D>[]) => void;
  defaultViewport?: 'auto' | 'narrow' | 'wide';
  autoResetSelectedRows?: boolean;
  autoResetSortBy?: boolean;
  getRowId?: UseTableOptions<D>['getRowId'];
  autoResetFilters?: boolean;
  loading?: boolean;
  showSearch?: boolean;
};

export const DataGrid = <D extends RecordAny>({
  className,
  totalCount,
  data,
  columns,
  disableGlobalFilter,
  globalFilter,
  disableFilters,
  topToolbar,
  selectable,
  rowContent,
  initialState,
  onSelectedRowsChange,
  defaultViewport = 'auto',
  autoResetSelectedRows,
  autoResetSortBy,
  getRowId,
  autoResetFilters,
  loading,
  showSearch,
  ...props
}: DataGridProps<D>) => {
  const viewport_ = useViewport();

  const viewport = useMemo(() => {
    if (defaultViewport === 'auto') return viewport_;
    else
      return {
        isNarrow: defaultViewport === 'narrow',
        isWide: defaultViewport === 'wide',
      };
  }, [defaultViewport, viewport_]);

  const [globalFilterVisible, toggleGlobalFilterVisible] = useToggle(
    false,
    toggleFn,
  );

  const [filtersVisible, toggleFiltersVisible] = useToggle(false, toggleFn);

  useEffect(() => {
    if (viewport.isWide) toggleGlobalFilterVisible(false);
  }, [viewport]);

  useKeyBindings({
    Enter: () => globalFilterVisible && toggleGlobalFilterVisible(false),
  });

  const table = useTable<D>(
    {
      data,
      columns,
      defaultColumn,
      disableGlobalFilter,
      globalFilter,
      initialState,
      autoResetSelectedRows,
      autoResetSortBy,
      getRowId,
      autoResetFilters,
      stateReducer(newState, action, previousState, instance) {
        if (action.type === 'toggleSortBy') {
          const column = instance.columns.find(
            col => col.id === action.columnId,
          );

          if (!column) return newState;

          let kind: false | 'asc' | 'desc';
          if (newState.sortBy.length === 0) {
            kind = false;
          } else {
            kind = newState.sortBy[0].desc ? 'desc' : 'asc';
          }

          column.onToggleSortBy?.(kind, action.columnId);
        }
        return newState;
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useRowSelection,
    selectable ? makeSelectionColumn : noop,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    flatHeaders,
    rows,
    rowsById,
    prepareRow,
    state,
    globalFilteredRows,
    setGlobalFilter,
    getToggleAllRowsSelectedProps,
    toggleAllRowsSelected,
    selectedFlatRows,
    selectedData,
    globalFilteredFlatRows,
    co,
  } = table;

  useEffect(() => {
    onSelectedRowsChange?.(selectedFlatRows);
  }, [selectedFlatRows]);

  useEffect(() => {
    if (!disableGlobalFilter) setGlobalFilter(state.globalFilter);
  }, [data]);

  const context = useMemo<DataGridContext<D>>(
    () => ({
      viewport,
      rows,
      rowsById,
      selectable,
      selectedFlatRows,
      toggleAllRowsSelected,
      filtersVisible,
      filteredFlatRows: globalFilteredFlatRows,
      selectedData,
      columns: table.columns,
    }),
    [
      rows,
      globalFilteredFlatRows,
      selectedFlatRows,
      viewport,
      filtersVisible,
      selectable,
      toggleAllRowsSelected,
      columns,
    ],
  );

  const tableComponents = useMemo<TableComponents<DataGridContext<D>>>(
    () => ({
      Table: ({ context, ...props }) => (
        <table
          {...mergeStyle(getTableProps(), props, {
            className: 'w-full',
          })}
        />
      ),
      TableHead: React.forwardRef(({ context, ...props }, ref) => (
        <thead
          className="text-left bg-white shadow-sm shadow-gray-200"
          {...props}
          ref={ref}
        />
      )),
      TableRow: ({ context, ...props }) => {
        const index = props['data-index'];

        const row = context.rowsById[index] || randomItem(context.rows);

        if (!row)
          return <tr style={{ height: props['data-known-size'] || 10 }} />;

        return (
          <tr
            data-id={row.id}
            {...mergeStyle(
              props,
              row.getRowProps?.(),
              {
                className: row.isSelected ? 'bg-blue-50' : 'bg-gray-50',
              },
              {
                className: context.rowsById[index]
                  ? 'relative'
                  : 'opacity-50 pointer-events-none',
              },
            )}
          />
        );
      },
      TableBody: React.forwardRef(({ context, ...props }, ref) => (
        <tbody
          {...mergeStyle(getTableBodyProps(), props, {
            className: 'text-gray-600',
          })}
          ref={ref}
        />
      )),
    }),
    [],
  );

  const headerContent = useCallback(() => {
    if (viewport.isNarrow) return <tr className="h-px" />;

    return (
      viewport.isWide &&
      headerGroups.map(headerGroup => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column, i, headers) => (
            <th
              data-id={column.id}
              {...mergeStyle(
                column.getHeaderProps(column.getSortByToggleProps()),
                {
                  className: `text-ellipsis
                              align-top px-1 pb-1 first:pl-1 last:pr-1 font-normal bg-gray-50`,
                  style: {
                    position: column.sticky ? 'sticky' : 'relative',
                    left:
                      i === 0 || !column.sticky
                        ? 0
                        : headers
                            .slice(0, i)
                            .reduce((v, item) => v + Number(item.width), 0),
                    zIndex: column.sticky ? 10 : 2,
                    width: column.width,
                    minWidth: column.minWidth,
                    maxWidth: column.maxWidth,
                  },
                },
              )}>
              <label
                className={`flex flex-nowrap pb-1 text-slate-900 font-normal cursor-pointer ${
                  column.className || ''
                }`}
                htmlFor={column.id}>
                <span className="flex-shrink overflow-hidden">
                  {column.render('Header')}
                </span>
                {column.isSorted && (
                  <Icon
                    className="flex-shrink-0 text-blue-500 text-lg transition-transform ml-1"
                    rotate={column.isSortedDesc ? 180 : 0}
                    as={ArrowUpLineIcon}
                  />
                )}
              </label>
              {filtersVisible && (
                <div onClick={stopPropagation}>
                  {column.canFilter && column.render('Filter')}
                </div>
              )}
            </th>
          ))}
        </tr>
      ))
    );
  }, [viewport, headerGroups, filtersVisible]);

  const itemContent = useMemo<ItemContent<void, DataGridContext<D>>>(() => {
    return (index, _, context) => {
      const { viewport, rowsById, rows } = context;
      const row: Row<D> = rowsById[index] || randomItem(rows);

      if (!row)
        return (
          <td className="flex flex-col items-stretch" colSpan={2}>
            {' '}
          </td>
        );

      prepareRow(row);
      const content = rowContent?.(index, row, context);

      if (content)
        return (
          <td className="flex flex-col items-stretch" colSpan={2}>
            {content}
          </td>
        );

      return (
        <>
          {viewport.isNarrow && (
            <td className="flex px-3 py-2 border-b">
              {row.cells.map(cell => (
                <div className="flex-1 px-2">
                  <div className="text-slate-900 font-normal pb-1">
                    {cell.column.render('Header')}
                  </div>
                  {cell.render('Cell')}
                </div>
              ))}
            </td>
          )}
          {viewport.isWide &&
            row.cells.map((cell, i, cells) => (
              <td
                {...mergeStyle(cell.getCellProps(), {
                  className: 'text-normal px-1 py-3 bg-gray-50',
                  style: {
                    position: cell.column.sticky ? 'sticky' : 'static',
                    left:
                      i === 0 || !cell.column.sticky
                        ? 0
                        : cells
                            .slice(0, i)
                            .reduce(
                              (v, item) => v + Number(item.column.width),
                              0,
                            ),
                    zIndex: 0,
                  },
                })}>
                <div>{cell.render('Cell')}</div>
                <div className="bg-gray-200 h-px absolute left-0 right-0 bottom-0" />
              </td>
            ))}
        </>
      );
    };
  }, [rowContent, state]);

  return (
    <div
      className={`${className} flex flex-col flex-nowrap rounded-lg overflow-hidden transition-all ${
        globalFilterVisible ? 'mt-4' : 'mt-1'
      } ${viewport.isWide && 'mx-7 border border-gray-200'}`}>
      {viewport.isNarrow && (
        <>
          {!disableGlobalFilter && (
            <div className="absolute top-1 right-2 z-10">
              <IconButton
                className="shadow-md shadow-slate-300"
                circle
                appearance="subtle"
                icon={<SearchIcon />}
                onClick={toggleGlobalFilterVisible}
              />
              {
                <DrawerStyled
                  placement="top"
                  open={globalFilterVisible}
                  onClose={toggleGlobalFilterVisible}
                  backdrop={false}
                  style={{ height: 'unset', zIndex: 9001 }}>
                  <div className="flex flex-row items-center justify-between pr-2 pl-4 py-3">
                    <GlobalFilter
                      globalFilter={state.globalFilter}
                      setGlobalFilter={setGlobalFilter}
                    />
                    <IconButton
                      className="ml-2"
                      circle
                      icon={<CloseIcon />}
                      onClick={toggleGlobalFilterVisible}
                    />
                  </div>
                </DrawerStyled>
              }
            </div>
          )}
          <div className="flex flex-row mx-1 items-center">
            {showSearch && (
              <GlobalFilter
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            )}
            {selectable && (
              <div className="my-1">
                <SelectRowCheckbox
                  {...mergeStyle(getToggleAllRowsSelectedProps(), {
                    className:
                      'rounded-lg border border-gray-200 box-border -my-1',
                  })}>
                  <span className="pr-1 inline-block" style={{ minWidth: 44 }}>
                    {selectedFlatRows.length || <FormattedMessage id="all" />}
                  </span>
                </SelectRowCheckbox>
              </div>
            )}
            {topToolbar && (
              <div className="flex flex-row flex-1 justify-end mx-1 my-1">
                {topToolbar(context)}
              </div>
            )}
          </div>
        </>
      )}
      {viewport.isWide && (
        <div className="global-filters flex flex-row items-center m-1 mb-2 relative">
          {!disableFilters && (
            <IconButton
              className={`mr-1 hover:shadow-md ${
                filtersVisible
                  ? 'focus:text-white focus:bg-blue-500 text-white bg-blue-500'
                  : 'bg-gray-100'
              }`}
              tabIndex={0}
              onClick={toggleFiltersVisible}
              size="sm"
              icon={<BiFilter size={18} className="rs-icon -m-px" />}
            />
          )}
          {!disableGlobalFilter && (
            <GlobalFilter
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          )}
          <div className="flex flex-row flex-1 justify-end ml-1">
            {topToolbar?.(context)}
          </div>
        </div>
      )}

      <div className="flex-1 border-b border-stale-50">
        <TableVirtuoso
          {...mergeStyle(props, { className: 'h-full' })}
          totalCount={totalCount || rows.length}
          context={context}
          components={tableComponents}
          fixedHeaderContent={headerContent}
          itemContent={itemContent}
        />
        {loading && <Loader className="absolute left-1/2 top-1/2" size="md" />}
      </div>

      <div className="text-slate-800 my-2 ml-2">
        <FormattedMessage
          id="pagination.total"
          values={{
            total: (
              <b>
                <FormattedNumber
                  value={
                    state.globalFilter
                      ? globalFilteredRows.length
                      : totalCount || rows.length
                  }
                  numberStyle="decimal"
                />
              </b>
            ),
          }}
        />
      </div>
    </div>
  );
};

function randomItem<T>(list: T[]) {
  return list[Math.trunc(Math.random() * list.length)];
}
/**
 * TODO:
 * Keybindings: Esc, Select with 'Space', 'Enter', navigate with 'Up', 'Down'
 * Mobile: Swipe Down to close modal
 */
